import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import Header from './components/Header';
import { fetchParameterData } from './state/parameterFetch';
import { useDispatch } from 'react-redux';
import { Drawer } from '@mui/material';
import { closeParameter } from '../../state/application/applicationAction';
import { setTab } from './state/parameterAction';
import Loading from '../../components/Loading';

const Cidade = React.lazy(() => import('./pages/cidade/Cidade'));
const Rota = React.lazy(() => import('./pages/rota/Rota'));
const Setor = React.lazy(() => import('./pages/setor/Setor'));
const Cliente = React.lazy(() => import('./pages/cliente/Cliente'));
const Parametro = React.lazy(() => import('./pages/parametro/Parametro'));
const Usuario = React.lazy(() => import('./pages/usuario/Usuario'));

var abas = [
  { label: 'Cidade', Component: Cidade },
  { label: 'Rota', Component: Rota },
  { label: 'Setor', Component: Setor },
  { label: 'Cliente', Component: Cliente },
  { label: 'Parametro', Component: Parametro },
  { label: 'Usuário', Component: Usuario },
].sort((a, b) => a.label.localeCompare(b.label));

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ value, index, children }: TabPanelProps) => {
  return (
    <div hidden={value !== index} className='h-full bg-[#f7f9fc]'>
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
};

const Parameter = () => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchParameterData());
  }, []);

  const tab = useSelector((state: RootState) => state.parameter.tab);

  const isParameterOpen = useSelector(
    (state: RootState) => state.application.isParameterOpen
  );

  return (
    <Drawer
      PaperProps={{
        sx: { width: '90%' },
      }}
      variant='temporary'
      open={isParameterOpen}
      onClose={() => dispatch(closeParameter())}
    >
      <div className='h-full grid grid-rows-[112px_auto] w-full'>
        <div>
          <Header />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={(_, newValue) => dispatch(setTab(newValue))}
            >
              {abas.map((aba, index) => (
                <Tab key={index} label={aba.label} />
              ))}
            </Tabs>
          </Box>
        </div>
        <div className='overflow-auto'>
          {abas.map((aba, index) => (
            <TabPanel key={index} value={tab} index={index}>
              <React.Suspense fallback={<Loading />}>
                <aba.Component />
              </React.Suspense>
            </TabPanel>
          ))}
        </div>
      </div>
    </Drawer>
  );
};
export default Parameter;
