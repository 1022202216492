export const formatCurrency = (value: number, digits: number) => {
  if (value) {
    return (
      'R$' +
      value.toLocaleString(undefined, {
        maximumFractionDigits: digits,
      })
    );
  } else {
    return 'R$ 0,00';
  }
};

export const formatPeso = (value: number, digits: number) => {
  return (
    value.toLocaleString(undefined, {
      maximumFractionDigits: digits,
    }) + ' Kg'
  );
};

export const formatPesoTon = (value: number) => {
  return (
    (value / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }) + ' TON'
  );
};

export const formatMetroCubico = (value: number, digits: number) => {
  return (
    value.toLocaleString(undefined, {
      maximumFractionDigits: digits,
    }) + ' M3'
  );
};

export const formatPercent = (value: number, digits: number) => {
  return (
    value.toLocaleString(undefined, {
      maximumFractionDigits: digits,
    }) + '%'
  );
};

export const formatNumber = (value: number, digits: number) => {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: digits,
  });
};

export const getDate = (date: string) => {
  return new Date(
    date.substr(6, 4) +
      '-' +
      date.substr(3, 2) +
      '-' +
      date.substr(0, 2) +
      'T00:00:00'
  );
};

export const formatDate = (dateString: string | undefined) => {
  if (dateString === null || dateString === undefined) {
    return '';
  }
  const date = new Date(dateString);

  // Extrair componentes da data em UTC
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // O mês é base 0, então adicionamos 1
  const year = date.getUTCFullYear();

  // Formatar a data no formato desejado (dd/MM/yyyy)
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const formatDataHora = (dataString: string) => {
  const data = new Date(dataString);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  const hora = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');
  const segundos = String(data.getSeconds()).padStart(2, '0');

  return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
};
