import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getNotasCarregamento } from '../../../services/CarregamentoService';
import {
  formatCurrency,
  formatDataHora,
  formatDate,
  formatMetroCubico,
  formatPeso,
} from '../../../utils/formatter';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DownloadFichaCarregamento = async (
  idCarregamento,
  notas,
  rota,
  observacao,
  data
) => {
  const fichaCarregamentoList = await getNotasCarregamento(idCarregamento);

  fichaCarregamentoList.sort((a, b) => {
    if (parseInt(a.ordemCarregamento) > parseInt(b.ordemCarregamento)) {
      return 1;
    }
    return -1;
  });

  var pesoRealTotal = 0;
  var pesoCubadoTotal = 0;
  var m3Total = 0;
  var valorMercadoriaTotal = 0;

  var rows = [];
  var nomeCidade = '';
  var color1 = '#ffffff';
  var color2 = '#bbbbbb';
  var color = color2;
  var obsAgendamento = '';
  for (let i = 0; i < fichaCarregamentoList.length; i++) {
    const nota = notas.find(
      (nota) => nota.idNota === fichaCarregamentoList[i].idNota
    );

    if (nomeCidade !== nota.cidade) {
      nomeCidade = nota.cidade;
      if (color === color1) {
        color = color2;
      } else {
        color = color1;
      }
    }

    var numeroNotaFiscal = nota.numeroNotaFiscal.match(/(?<=\/)\d*/g);
    if (numeroNotaFiscal != null && numeroNotaFiscal.length > 1) {
      numeroNotaFiscal = numeroNotaFiscal[0] + '*';
    } else {
      numeroNotaFiscal = numeroNotaFiscal[0];
    }

    var nomeDestinatario = nota.destinatario.substr(0, 10);

    if (nota.agendado) {
      nomeDestinatario += ' (AGD)';
    }

    if (nota.dataAgendamento != null) {
      obsAgendamento +=
        '\n\nNF ' +
        numeroNotaFiscal +
        ' - ' +
        nota.remetente +
        ' -> ' +
        nota.destinatario +
        ' AGENDADA P/' +
        formatDate(nota.dataAgendamento);
    }

    rows.push([
      {
        alignment: 'center',
        text: formatDate(nota.dataAutorizacao),
        fillColor: color,
      },
      {
        alignment: 'center',
        text: formatDate(nota.dataPrevisaoEntrega),
        fillColor: color,
      },
      {
        alignment: 'center',
        text: nota.remetente.substr(0, 10),
        fillColor: color,
      },
      {
        alignment: 'center',
        text: nomeDestinatario,
        fillColor: color,
      },
      {
        alignment: 'center',
        text: nota.cidade.substr(0, 10),
        fillColor: color,
      },
      {
        alignment: 'center',
        text: numeroNotaFiscal,
        fillColor: color,
        bold: true,
      },
      { alignment: 'center', text: nota.totalVolumes, fillColor: color },
      {
        alignment: 'right',
        text: formatPeso(nota.pesoReal, 0),
        fillColor: color,
      },
      {
        alignment: 'right',
        text: formatPeso(nota.pesoCubado, 0),
        fillColor: color,
      },
      {
        alignment: 'right',
        text: formatCurrency(nota.valorMercadoria, 0),
        fillColor: color,
      },
    ]);

    pesoRealTotal = pesoRealTotal + parseFloat(nota.pesoReal);
    pesoCubadoTotal = pesoCubadoTotal + parseFloat(nota.pesoCubado);
    m3Total = pesoCubadoTotal / 300;
    valorMercadoriaTotal = valorMercadoriaTotal + nota.valorMercadoria;
  }

  const rows1 = JSON.parse(JSON.stringify(rows));
  const rows2 = JSON.parse(JSON.stringify(rows));
  const rows3 = JSON.parse(JSON.stringify(rows));
  const rows4 = JSON.parse(JSON.stringify(rows));

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [20, 20, 20, 20],
    // images: {
    //   snow: 'https://trackercloud.com.br/logo.png',
    // },
    content: [
      {
        style: 'header',
        layout: 'noBorders',
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              // {
              //   image: 'snow',
              //   margin: [0, 0, 0, 30],
              //   alignment: 'left',
              //   fit: [150, 150],
              // },
              {
                text: '[' + rota.unidade.nome + '] ' + rota.nome.toUpperCase(),
                style: 'rota',
                alignment: 'center',
                margin: [0, 12, 0, 30],
              },
            ],
          ],
        },
      },
      {
        style: 'header',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: idCarregamento,
                alignment: 'left',
                style: { fontSize: 8, bold: true },
              },
              {
                text: '1a via CONFERENTE',
                alignment: 'right',
                style: { fontSize: 8, bold: true },
              },
            ],
          ],
        },
      },
      {
        style: 'table',
        table: {
          widths: [
            'auto',
            'auto',
            '*',
            '*',
            '*',
            'auto',
            'auto',
            'auto',
            'auto',
            'auto',
          ],
          body: [
            [
              {
                text: 'CONFERENTE:',
                colSpan: 3,
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 10],
              },
              '',
              '',
              {
                text: 'MOTORISTA:',
                colSpan: 3,
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 10],
              },
              '',
              '',
              {
                text: `DATA CRIAÇÃO: ${formatDataHora(data)}`,
                colSpan: 4,
                fontSize: 10,
                bold: true,
                margin: [0, 10, 0, 10],
              },
              '',
              '',
              '',
            ],
            [
              { alignment: 'center', text: 'DATA REC.', style: { bold: true } },
              { alignment: 'center', text: 'DATA LIM.', style: { bold: true } },
              { alignment: 'center', text: 'REMETENTE', style: { bold: true } },
              {
                alignment: 'center',
                text: 'DESTINATÁRIO',
                style: { bold: true },
              },
              { alignment: 'center', text: 'CIDADE', style: { bold: true } },
              { alignment: 'center', text: 'NF', style: { bold: true } },
              { alignment: 'center', text: 'VOL.', style: { bold: true } },
              { alignment: 'center', text: 'PES. REAL', style: { bold: true } },
              {
                alignment: 'center',
                text: 'PES. CUBADO',
                style: { bold: true },
              },
              {
                alignment: 'center',
                text: 'V. MERC.',
                style: { bold: true },
              },
            ],
            ...rows1,
            [
              {
                text: 'TOTAL DE CTES: ' + fichaCarregamentoList.length,
                bold: true,
                colSpan: 2,
                fontSize: 10,
              },
              '',
              {
                text: 'TOTAL PESO:',
                bold: true,
                colSpan: 5,
                fontSize: 10,
                alignment: 'right',
              },
              '',
              '',
              '',
              '',
              {
                text: formatPeso(pesoRealTotal, 0),
                bold: true,
                alignment: 'right',
              },
              {
                text:
                  formatPeso(pesoCubadoTotal, 0) +
                  ' ' +
                  formatMetroCubico(m3Total, 0) +
                  '',
                bold: true,
                alignment: 'right',
              },
              {
                text: formatCurrency(valorMercadoriaTotal, 0),
                bold: true,
                alignment: 'right',
              },
            ],
            [
              { fontSize: 10, text: 'QTD PALLET PBR:', colSpan: 3, bold: true },
              '',
              '',
              { fontSize: 10, text: 'QTD GAIOLA:', colSpan: 3, bold: true },
              '',
              '',
              { fontSize: 10, text: 'QTD CHAPATEX:', colSpan: 4, bold: true },
              '',
              '',
              '',
            ],
          ],
        },
      },
      {
        layout: 'noBorders',
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: `OBSERVAÇÕES: ${observacao + obsAgendamento}`,
                fontSize: 10,
                bold: true,
                margin: [0, 5, 0, 0],
              },
            ],
          ],
        },
      },
    ],
    footer: (currentPage, pageCount) => {
      var footer = {
        layout: 'noBorders',
        fontSize: 8,
        table: {
          widths: ['*'],
          body: [
            [
              {
                alignment: 'center',
                text: 'Página  ' + currentPage.toString() + ' de ' + pageCount,
              },
            ],
          ],
        },
      };

      return footer;
    },
    styles: {
      header: {
        height: 50,
        border: 0,
      },
      table: {
        fontSize: 8,
        color: 'black',
      },
      rota: {
        fontSize: 20,
        bold: true,
      },
    },
  };

  await pdfMake
    .createPdf(docDefinition)
    .download(rota.nome + '' + idCarregamento + '.pdf');
};
export default DownloadFichaCarregamento;
