import { MdDashboard, MdSchedule } from 'react-icons/md';
import { BsFillGearFill, BsTriangle } from 'react-icons/bs';
import { IoDocuments, IoTime } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  openParameter,
  openParameterTab,
} from '../../../state/application/applicationAction';
import { FaTruck } from 'react-icons/fa';
import { FaCalendarDays } from 'react-icons/fa6';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Transition } from '@headlessui/react';
import { AppDispatch } from '../../../state/store';
import Feedback from '../../../components/Feedback';
import { IoIosAlert } from 'react-icons/io';
import { FaClock } from 'react-icons/fa';
import { SiTarget } from 'react-icons/si';
import { FaTruckRampBox } from 'react-icons/fa6';
import { FaPhoneVolume } from 'react-icons/fa6';

type SidebarProps = {
  sideBarWidth: string;
  setSideBarWidth: Function;
};

const Sidebar = ({ sideBarWidth, setSideBarWidth }: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  var items = [
    {
      onClick: () => navigate('/agendados'),
      path: '/agendados',
      label: 'Agendados',
      icon: <FaCalendarDays />,
    },
    {
      onClick: () => navigate('/carregamento'),
      path: '/carregamento',
      label: 'Carregamento',
      badge: 5,
      icon: <FaTruck />,
    },

    {
      onClick: () => navigate('/agendamento'),
      path: '/agendamento',
      label: 'Agendamentos',
      icon: <FaPhoneVolume />,
    },
    {
      onClick: () => navigate('/atrasadas'),
      path: '/atrasadas',
      label: 'Atrasadas',
      icon: <FaClock />,
    },
    {
      onClick: () => navigate('/canhotos'),
      path: '/canhotos',
      label: 'Canhotos',
      icon: <IoDocuments />,
    },
    {
      onClick: () => navigate('/entregas'),
      path: '/entregas',
      label: 'Entregas',
      icon: <FaTruckRampBox />,
    },
    {
      onClick: () => navigate('/monitorados'),
      path: '/monitorados',
      label: 'Monitorados',
      icon: <SiTarget />,
    },
    {
      onClick: () => navigate('/pendencia'),
      path: '/pendencia',
      label: 'Pendência',
      icon: <IoIosAlert />,
    },
    {
      onClick: () => dispatch(openParameter()),
      label: 'Configuração',
      icon: <BsFillGearFill />,
      hideMobile: true,
    },
  ];
  return (
    <div
      className={` ${
        sideBarWidth === '80'
          ? ''
          : 'absolute w-full grid grid-cols-[250px_auto]'
      }   h-full lg:relative lg:w-full  overflow-hidden z-50`}
    >
      <div
        className={`${
          sideBarWidth === '80' ? '' : 'w-[250px]'
        } bg-primary-600 h-full`}
      >
        <div className='h-[60px] bg-white flex justify-center items-center '>
          <Transition
            show={sideBarWidth === '250'}
            enter='transition duration-[500ms] delay-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
          >
            {sideBarWidth === '250' && (
              <img
                src='/tracker_bg_dark.png'
                className='h-[30px]'
                alt='image'
              />
            )}
          </Transition>
        </div>
        {items.map((item, index) => (
          <div
            onClick={() => item.onClick()}
            key={index}
            className={`
            ${index === 0 ? 'mt-5' : ''}
            ${
              item.hideMobile ? 'invisible lg:visible' : ''
            } w-full h-[30px] hover:bg-gradient-to-r from-[#1565c0] to-[#2272ce] transition duration-300  py-5 flex items-center cursor-pointer`}
          >
            {sideBarWidth === '80' ? (
              // <Badge badgeContent='5' color='error'>
              <div className='text-[#59d0ff] pl-[32px]'>{item.icon}</div>
            ) : (
              // </Badge>
              <div className='text-[#59d0ff] pl-[32px]'>{item.icon}</div>
            )}

            <Transition
              show={sideBarWidth === '250'}
              enter='transition duration-[100ms]'
              enterFrom='opacity-0'
              enterTo='opacity-100'
            >
              <div className='flex w-full'>
                <div className='text-[#8BA1B7] pl-5 text-sm'>{item.label}</div>
                <div className='w-full flex justify-end pr-5'>
                  {/* <div className='bg-[#FF0000] rounded-full text-white w-5 h-5 text-xs flex justify-center items-center'>
                  5
                </div> */}
                </div>
              </div>
            </Transition>
            <div className='w-full flex justify-end text-gray-200'>
              {location.pathname === item.path ? (
                <div>
                  <ArrowForwardIosIcon className='w-5 h-5' />
                </div>
              ) : null}
            </div>
          </div>
        ))}

        <Feedback size={sideBarWidth} />
      </div>
      <div
        onClick={() => setSideBarWidth('80')}
        className='lg:hidden h-full w-full bg-primary-300 bg-opacity-50'
      ></div>
    </div>
  );
};
export default Sidebar;
