import { createSlice } from '@reduxjs/toolkit';

export interface UsuarioState {
  loading: boolean;
  form: {
    isOpen: boolean;
  };
}

const initialState = {
  loading: false,
  cidades: [],
  form: {
    isOpen: false,
  },
} as UsuarioState;

const usuarioSlice = createSlice({
  name: 'usuario',
  initialState,
  reducers: {
    openForm: (state, action) => {
      state.form.isOpen = true;
    },
    closeForm: (state) => {
      state.form.isOpen = false;
    },
  },
});
export default usuarioSlice.reducer;
