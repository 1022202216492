import { Entrega } from '../data/types/Entrega';
import { Nota } from '../data/types/Nota';
import {
  getCurrentDateInBRTFormat,
  getCurrentDatePlusOneInBRTFormat,
} from '../utils/date';
import { getRequest } from './service';

// export const getPaginaCarregamento = async (): Promise<<Nota[]> => {
//   return await getRequest<Nota[]>('/pagina/carregamento');
// };

export const getPaginaCarregamento = async (): Promise<Nota[]> => {
  return await getRequest<Nota[]>('/pagina/carregamento');
};

export const getPaginaAtrasadas = async (): Promise<any> => {
  return await getRequest<Nota[]>('/pagina/atrasadas');
};

export const getPaginaCanhotos = async (): Promise<any> => {
  return await getRequest<Nota[]>('/pagina/canhotos');
};

export const getPaginaMonitorados = async (): Promise<any> => {
  return await getRequest<Nota[]>('/pagina/monitorados');
};

export const getPaginaAgendamento = async (): Promise<any> => {
  return await getRequest<Nota[]>('/pagina/agendamentos');
};

export const getPaginaAgendadas = async (
  inicio: string,
  fim: string
): Promise<Nota[]> => {
  return await getRequest<Nota[]>(
    `/pagina/agendadas?inicio=${inicio}&fim=${fim}`
  );
};

export const getPaginaPendencia = async (): Promise<any> => {
  return await getRequest<Nota[]>('/pagina/pendencia');
};

export const getPaginaEntregas = async (): Promise<Entrega[]> => {
  const inicio = getCurrentDateInBRTFormat();
  const fim = getCurrentDatePlusOneInBRTFormat();
  return await getRequest<any>(`/pagina/entregas?inicio=${inicio}&fim=${fim}`);
};
